'use client';
import styled from 'styled-components';

export const ProductSelectionContainer = styled.div`
  min-width: 0;
  width: fit-content;
  max-width: 100vw;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing(0, 4)};
  gap: ${props => props.theme.spacing(4)};
  margin: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  @media ${props => props.theme.laptop} {
    padding: 0;
    margin: ${props => props.theme.spacing(0, 'auto', 10, 'auto')};
  }
`;

export const ProductCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-width: min-content;
`;

export const MenuWrapper = styled.div`
  grid-column: span 3;
  width: 90vw;
  position: sticky;
  left: 0;
  @media ${props => props.theme.tablet} {
    width: 100%;
    position: static;
  }
`;
